import { createEnv } from '@t3-oss/env-nextjs'
import { z } from 'zod'
import { Environments } from './app.types'
import { languages, SupportedLocales } from './constants'

const config = createEnv({
  client: {
    NEXT_PUBLIC_ENVIRONMENT: z.string(),
    NEXT_PUBLIC_BANKID_HOST: z.string(),
    NEXT_PUBLIC_CLIENT_M3B: z.string(),
    NEXT_PUBLIC_CLIENT_B2B_SELF_SERVICE_SE: z.string(),
    NEXT_PUBLIC_CLIENT_B2B_SELF_SERVICE_DK: z.string(),
    NEXT_PUBLIC_SERVICE_MESSAGE: z.string(),
    NEXT_PUBLIC_CLIENT_MY3DK: z.string(),
    NEXT_PUBLIC_CLIENT_MY3SE_WEB: z.string(),
    NEXT_PUBLIC_CLIENT_MY3SE_IOS: z.string(),
    NEXT_PUBLIC_CLIENT_MY3SE_ANDROID: z.string(),
    NEXT_PUBLIC_CLIENT_AI_BOOST_CHATBOT: z.string(),
    NEXT_PUBLIC_CLIENT_INSIDED: z.string(),
  },
  server: {
    ENVIRONMENT: z.string(),
    API_HOST: z.string(),
    CONTENTFUL_SPACE_ID: z.string(),
    TRE_B2B_CONTENTFUL_CMA_TOKEN: z.string(),
    CONTENTFUL_SCOPE_PREFIX: z.string(),
    MIT3_CLIENT_ID: z.string(),
  },
  runtimeEnv: {
    // fallback to b2bselfservice-se BT1
    NEXT_PUBLIC_CLIENT_B2B_SELF_SERVICE_SE:
      process.env.NEXT_PUBLIC_CLIENT_B2B_SELF_SERVICE_SE ??
      '00016195-e29c-11b3-8c23-b64c2ac70bbb',
    // fallback to b2bselfservice-dk BT1
    NEXT_PUBLIC_CLIENT_B2B_SELF_SERVICE_DK:
      process.env.NEXT_PUBLIC_CLIENT_B2B_SELF_SERVICE_DK ??
      '78f58f16-2523-6464-ba3f-7af0f9fa7dda',
    NEXT_PUBLIC_CLIENT_M3B:
      process.env.NEXT_PUBLIC_CLIENT_M3B ??
      '48ae0f72-b9c6-6143-c11e-9ef950426af6',
    NEXT_PUBLIC_CLIENT_MY3DK:
      process.env.NEXT_PUBLIC_CLIENT_MY3DK ??
      '88b41d43-3edf-6b7e-03f1-90e0263d522a',
    NEXT_PUBLIC_CLIENT_MY3SE_WEB:
      process.env.NEXT_PUBLIC_CLIENT_MY3SE_WEB ??
      '0309d174-3fb1-4809-add7-0cf045012305',
    NEXT_PUBLIC_CLIENT_MY3SE_ANDROID:
      process.env.NEXT_PUBLIC_CLIENT_MY3SE_ANDROID ??
      'e07d319d-26ab-48ec-8076-9bb7b9d2ed05',
    NEXT_PUBLIC_CLIENT_MY3SE_IOS:
      process.env.NEXT_PUBLIC_CLIENT_MY3SE_IOS ??
      'd78916a3-bd40-4e6c-95c8-c4121e1688b9',
    NEXT_PUBLIC_CLIENT_AI_BOOST_CHATBOT:
      process.env.NEXT_PUBLIC_CLIENT_AI_BOOST_CHATBOT ??
      'e5238f5e-b86a-01d9-d63d-db1e35d20b9b',
    NEXT_PUBLIC_CLIENT_INSIDED:
      process.env.NEXT_PUBLIC_CLIENT_INSIDED ??
      '2e8b49e0-43ba-4bff-3a09-b6a5ea7d1476',

    NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT ?? 'local',
    NEXT_PUBLIC_BANKID_HOST:
      process.env.NEXT_PUBLIC_BANKID_HOST ?? 'https://api.nextrel.tre.se',
    TRE_B2B_CONTENTFUL_CMA_TOKEN:
      process.env.TRE_B2B_CONTENTFUL_CMA_TOKEN ?? '',
    ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT ?? 'local',
    API_HOST: process.env.API_HOST ?? 'https://customer-login.nextrel.tre.se',
    CONTENTFUL_SPACE_ID: 'h4e8ym7mtkuu',
    CONTENTFUL_SCOPE_PREFIX: 'customer-login',
    NEXT_PUBLIC_SERVICE_MESSAGE: process.env.NEXT_PUBLIC_SERVICE_MESSAGE ?? '',
    MIT3_CLIENT_ID:
      process.env.MIT3_CLIENT_ID ?? '88b41d43-3edf-6b7e-03f1-90e0263d522a',
  },
})

export const loggerByEnvironment: Environments = {
  production: 'https://www.tre.se/logger',
  stage: 'https://stage.webtest.tre.se/logger',
  test: 'https://test.webtest.tre.se/logger',
  local: 'http://localhost:9004/logger',
} as const satisfies Environments

export const WebBaseUrlByEnvironment: Environments = {
  production: 'myaccount.',
  stage: 'myaccountstage.webtest.',
  test: 'myaccounttest.webtest.',
  local: 'localhost:3000',
}

export const isDevelopment = process.env.NODE_ENV === 'development'

export const DEFAULT_LOCALE: SupportedLocales = languages.se

export default config
