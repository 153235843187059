'use client'
import { useClientCookie } from '@/app/hooks/useCookie/useClientCookie'
import useFormatMessage from '@/app/hooks/useFormatMessage'
import { CookieConstants, languages, SupportedLocales } from '@/constants'
import { useLocaleContext } from '@/providers/LocaleProvider'
import { setCookie } from '@/utils/cookie'
import { isServer } from '@/utils/isServer'
import { PropsWithChildren } from 'react'
import { DropdownMenu, DropdownMenuItem } from '../Dropdown'
import messages from './header.messages'
import Image from 'next/image'

const messageObj = {
  [languages.se]: messages.sv,
  [languages.dk]: messages.da,
  [languages.en]: messages.en,
}

export const LanguageDropdownItem = ({
  option,
}: {
  option: SupportedLocales
}) => {
  const localeContext = useLocaleContext()
  const formatMessage = useFormatMessage()
  const clickHandler = () => {
    if (option !== localeContext.locale && option) {
      setCookie(CookieConstants.Language, option)
      localeContext.setLocale(option)
    }
  }
  const disabled = option === localeContext.locale
  return (
    <DropdownMenuItem
      key={option}
      onClick={clickHandler}
      disabled={disabled}
      id={`language-dropdown-option-${option}`}
    >
      {formatMessage(messages[option])}
    </DropdownMenuItem>
  )
}

export const LanguageDropdown = ({ children }: PropsWithChildren) => {
  const formatMessage = useFormatMessage()
  const localeContext = useLocaleContext()
  const { lang } = useClientCookie(false)
  if (lang !== localeContext.locale) {
    if (!isServer()) setCookie(CookieConstants.Language, localeContext.locale)
  }
  const triggerText = messageObj[localeContext.locale] || messages.language

  return (
    <DropdownMenu
      triggerId="language-dropdown-trigger"
      trigger={
        <div className="flex items-center p-3 text-base font-normal leading-tight">
          <span className="mr-2">{formatMessage(triggerText)}</span>
          <Image width={16} height={16} alt="" src="/globe.svg" />
        </div>
      }
    >
      {children}
    </DropdownMenu>
  )
}
