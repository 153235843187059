'use client'
import otpMessages from '@/app/(views)/customer/login/verify/otpform.messages'
import useFormatMessage from '@/app/hooks/useFormatMessage'
import Message from '@/components/Message'
import { LoginFailedPath, SessionConstants } from '@/constants'
import { useGlobalContext } from '@/contexts/GlobalContext'
import { clientError, ErrorType } from '@/utils/errorMapper'
import isProduction from '@/utils/isProduction'
import { isServer } from '@/utils/isServer'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { createPortal } from 'react-dom'
import messages from './errors.messages'

type ErrorMessages = Record<ErrorType, string>

const ErrorMessage = ({ error }: { error: string | React.ReactNode }) => (
  <div className="text-base">{error}</div>
)

const displayErrorAbove = [LoginFailedPath]

const formatTitle = (message: string, errorCode?: number | string) => {
  const code = errorCode && !isProduction() ? ` (${errorCode})` : ''
  return `${message}${code}`
}

const ErrorCatcher = () => {
  let error
  let errorTitle
  const router = useRouter()
  const searchParams = useSearchParams()
  const formatMessage = useFormatMessage()
  const context = useGlobalContext()
  const path = usePathname()
  const errorCode =
    searchParams.get('errorCode') ??
    (!isServer()
      ? window.sessionStorage.getItem(SessionConstants.ErrorCode)
      : '')
  if (errorCode && !isServer()) {
    window.sessionStorage.setItem(SessionConstants.ErrorCode, errorCode)
  }

  const errorLocationId = displayErrorAbove.includes(path)
    ? 'top-error'
    : (context.error?.errorLocationId ?? '')

  const topErrorElement =
    !isServer() && errorLocationId && document.getElementById(errorLocationId)

  const closeMessage = () => {
    if (errorCode) {
      window.sessionStorage.removeItem(SessionConstants.ErrorCode)
      const params = new URLSearchParams(window.location.search)
      params.delete('errorCode')
      const newUrl = `${window.location.origin}${window.location.pathname}?${params.toString()}`
      router.replace(newUrl)
    }
    if (context.error) {
      context.setError()
    }
  }

  if (errorCode) {
    const clientErrorCode = clientError[parseInt(errorCode)]
    const errorMessages: ErrorMessages = {
      [ErrorType.UNKNOWN]: formatMessage(messages.genericErrorMessage),
      [ErrorType.CANCELED]: formatMessage(messages.userCanceled),
      [ErrorType.WRONGOTP]: formatMessage(otpMessages.incorrectCodeMessage),
    }
    error =
      errorMessages[clientErrorCode] ??
      formatMessage(messages.genericErrorMessage)
    errorTitle = formatTitle(
      formatMessage(messages.genericErrorTitle),
      errorCode,
    )
  } else if (context.error) {
    error = context.error.content
    errorTitle = formatTitle(context.error.title, context.error.errorCode)
  } else if (!errorCode && !context.error) {
    closeMessage()
  }

  useEffect(() => {
    if (context.error?.page !== path || !context.error.page) {
      context.setError()
      window.sessionStorage.removeItem(SessionConstants.ErrorCode)
    }
  }, [path])

  if (!error) return null

  const errorComponent = (
    <Message
      scrollIntoView
      variant="error"
      onClose={closeMessage}
      title={errorTitle ?? formatMessage(messages.genericErrorTitle)}
      content={<ErrorMessage error={error} />}
    />
  )

  if (errorLocationId && topErrorElement) {
    return createPortal(
      <div className="mb-6">{errorComponent}</div>,
      topErrorElement,
    )
  } else {
    return errorComponent
  }
}

export default ErrorCatcher
